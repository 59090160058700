export const allPortfolio = [
  {
    id: '1',
    title: 'Kistabandi Ecommerce Site',
    image: 'assets/kistabands.png',
    path: 'https://www.kistabandi.com',
  },
  {
    id: '2',
    title: 'dynoAcademy Learning Platform',
    image: 'assets/dynoacademy.png',
    path: 'https://dynoacademy.com',
  },
  {
    id: '3',
    title: 'Airbnb Clone Reactjs/Nextjs',
    image: 'assets/airbnb1.png',
    path: 'https://airbnb-clone-hulu-app.vercel.app/',
  },
  {
    id: '4',
    title: 'React Admin Dashboard',
    image: 'assets/admin_dashboard.png',
    path: 'https://phenomenal-mermaid-752e48.netlify.app/',
  },
  {
    id: '5',
    title: 'React crypto app with Redux',
    image: 'assets/crypto.png',
    path: 'https://eclectic-madeleine-b11b88.netlify.app/',
  },
]
