import React from 'react'

const MyCv = () => {
  return (
    <>
      <div className="flex justify-center px-5">
        <img src={'assets/sudeepcv.jpg'} alt="" />
      </div>
    </>
  )
}

export default MyCv
