export const navLinks = [
  {
    id: 1,
    title: 'Home',
    path: '#',
  },
  {
    id: 2,
    title: 'Features',
    path: '#feature',
  },
  {
    id: 3,
    title: 'Portfolio',
    path: '#portfolio',
  },
  {
    id: 4,
    title: 'Resume',
    path: '#resume',
  },
  {
    id: 5,
    title: 'Contact',
    path: '#contact',
  },
]
